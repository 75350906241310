export const dealRequests = (state) => state.dealRequests
export const totalRequests = (state) => state.totalRequests
export const requestPage = (state) => state.requestPage
export const requestPages = (state) => state.requestPages
export const dealInfo = (state) => state.dealInfo
export const dealMembers = (state) => state.dealMembers
export const dealInvestors = (state) => state.dealInvestors
export const DealTransactions = (state) => state.DealTransactions
export const totalTransactions = (state) => state.totalTransactions
export const page = (state) => state.page
export const pages = (state) => state.pages
export const members = (state) => state.members