import auth from "@/services/auth";
import org from "@/services/org";
import api from "@/utils/api.js";
import responseHandler from "@/utils/responseHandler.js";
import { getItem, setItem } from "@/utils/storage.js";

export const setOrganisationProfile = ({ commit }, payload) => {
	const total = payload.members_count;
	commit("SET_ORGANISATION_DETAILS", payload);
	commit("SET_TOTAL_MEMBERS", total);
};

export const setPin = ({ commit }, payload) => {
	commit("SET_PIN", payload);
};

export const setCurrency = ({ commit }, payload) => {
	commit("SET_CURRENCY", payload);
};

export const setWithdrawMethod = ({ commit }, payload) => {
	commit("SET_WITHDRAW_METHOD", payload);
};

export const setFundMethod = ({ commit }, payload) => {
	commit("SET_FUND_METHOD", payload);
};

export const setCardDetails = ({ commit }, payload) => {
	commit("SET_CARD_DETAILS", payload);
};

export const getOrganisationProfile = ({ dispatch }, payload) => {
	return new Promise((resolve, reject) => {
		const id = getItem("org_details") && getItem("org_details")._id;
		org
			.getOrganisationProfile(payload || id)
			.then((response) => {
				const { data } = response.data;
				setItem("org_details", data);
				dispatch("setOrganisationProfile", data);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				clearStorage();
				this.$router.push({ name: "SignIn" });
				reject(error);
			});
	});
};

export const updateOrgDetails = async ({ dispatch }, payload) => {
	dispatch("setLoading", true, { root: true });
	try {
		const response = await org.updateOrganisationDetails(payload);
		const { data } = response.data;
		const profile = await dispatch("getOrganisationProfile", data._id);
		if (profile) {
			return response;
		}
		dispatch("setLoading", false, { root: true });
	} catch (error) {
		responseHandler.handleError(error);
		clearStorage();
		this.$router.push({ name: "SignIn" });
		dispatch("setLoading", false, { root: true });
		return false;
	}
};

export const getOrganisationOverview = async ({ dispatch, commit }) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.getOrganisationOverview()
			.then((response) => {
				const { data } = response.data;
				const { deals, members, tokens } = data;
				commit("SET_OVERVIEW_DEALS", deals);
				commit("SET_OVERVIEW_MEMBERS", members);
				commit("SET_OVERVIEW_TOKENS", tokens);
				dispatch("setLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	})
};

export const getTransactions = async ({ commit, dispatch }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.getTransactions(payload);
		const { transactions, total, page, pages } = response.data.data;
		commit("SET_ORGANISATION_TRANSACTION", transactions);
		commit("SET_TOTAL_TRANSACTION", total);
		commit("SET_PAGE", page);
		commit("SET_PAGES", pages);
		dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		dispatch("setSmallLoading", false, { root: true });
		return false;
	}
};

export const getWalletTransaction = async ({ commit, dispatch }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.getWalletTransaction(payload);
		const { transactions, page, pages } = response.data.data;
		commit("SET_WALLET_TRANSACTION", transactions);
		commit("SET_WALLET_TRANSACTION_PAGE", page);
		commit("SET_WALLET_TRANSACTION_PAGES", pages);
		dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		dispatch("setSmallLoading", false, { root: true });
		return false;
	}
};

export const getWalletBalance = async ({ commit }) => {
	try {
		const walletResponse = await api.getWalletBalance();
		const allBalances = walletResponse.data.data;
		commit("SET_WALLET_BALANCE", allBalances);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const getExchangeRate = async ({ commit, dispatch }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.getExchangeRate(payload);
		const { rate } = response.data.data;
		commit("SET_EXCHANGE_RATE", rate);
		dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		dispatch("setSmallLoading", false, { root: true });
		return false;
	}
};

export const resetPasswordLink = async ({}, payload) => {
	try {
		const response = await auth.resetPasswordLink(payload);
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const resetPassword = async ({}, payload) => {
	try {
		const response = await auth.resetPassword(payload);
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const submitKycDocument = async ({ dispatch }, payload) => {
	dispatch("setLoading", true, { root: true });
	try {
		let response = null;
		const type = payload.documents[0].type; // or payload.type
		switch (type) {
			case "Other": // or case "Business"
				response = await org.submitOtherDocument(payload);
				break;

			default:
				response = await org.submitDocument(payload);
				break;
		}

		const { data } = response.data;
		await dispatch("getOrganisationProfile", data[0].uploader);
		dispatch("setLoading", false, { root: true });
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		dispatch("setLoading", false, { root: true });
		return false;
	}
};
export const updateKycDocument = async ({ dispatch }, payload) => {
	dispatch("setLoading", true, { root: true });
	try {
		let response = null;
		const type = payload.documents[0].type; // or payload.type
		switch (type) {
			case "Other": // or case "Business"
				response = await org.updateOtherDocument(payload);
				break;

			default:
				response = await org.submitDocument(payload);
				break;
		}

		const { data } = response.data;
		await dispatch("getOrganisationProfile", data[0].uploader);
		dispatch("setLoading", false, { root: true });
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		dispatch("setLoading", false, { root: true });
		return false;
	}
};

export const getNotifications = async ({}, payload) => {
	try {
		const response = await api.getNotifications(payload);
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const getTotalUnreadNotifications = async ({ commit }) => {
	try {
		const response = await api.getTotalUnreadNotifications();
		const { data } = response.data;
		commit("SET_NOTIFICATION_COUNT", data);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const activateMember = async ({ dispatch }, payload) => {
	try {
		const response = await api.activateMember(payload);
		const { message } = response.data;
		dispatch(
			"showToast",
			{ description: message, display: true, type: "success" },
			{ root: true }
		);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const deActivateMember = async ({ dispatch }, payload) => {
	try {
		const response = await api.deactivateMember(payload);
		const { message } = response.data;
		dispatch(
			"showToast",
			{ description: message, display: true, type: "success" },
			{ root: true }
		);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const removeMember = async ({ dispatch }, payload) => {
	return new Promise((resolve, reject) => {
		org
			.removeMember(payload)
			.then((response) => {
				const { message } = response.data;
				dispatch(
					"showToast",
					{
						description: message,
						display: true,
						type: "success",
					},
					{ root: true }
				);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				reject(error);
			});
	});
};

export const pendingInvite = async ({ dispatch }, payload) => {
	try {
		const response = await api.pendingInvite(payload);
		const { message } = response.data;
		dispatch(
			"showToast",
			{ description: message, display: true, type: "success" },
			{ root: true }
		);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const generateVirtualAccount = async ({ dispatch, commit }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		api
			.generateVirtualAccount(payload)
			.then((response) => {const virtualAccount = response.data.data;
				commit("SET_VIRTUAL_ACCOUNT", virtualAccount);
				dispatch("setSmallLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				console.log({ error });
				dispatch("setSmallLoading", false, { root: true });
				responseHandler.handleError(error);
				reject(error);
			});
	});
};

export const getVirtualAccount = async ({ dispatch, commit }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.getVirtualAccount(payload);
		const { data } = response.data;
		const virtualAccount = data.length > 0 ? data[0] : null;
		commit("SET_VIRTUAL_ACCOUNT", virtualAccount);

		dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		dispatch("setSmallLoading", false, { root: true });
		responseHandler.handleError(error);
		return false;
	}
};

export const getVirtualAccountFee = async ({}) => {
	// dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.getVirtualAccountFee();
		// dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		// dispatch("setSmallLoading", false, { root: true });
		responseHandler.handleError(error);
		return false;
	}
};

export const verifyPin = async ({ commit }, payload) => {
	try {
		await auth.verifyPin(payload);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const tradeToken = async ({}, payload) => {
	try {
		await api.tradeToken(payload);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const getMinimumWithdrawal = async ({ commit }, currency) => {
	try {
		const response = await api.getMinimumWithdrawal(currency);
		const { data } = response.data;
		commit("SET_MINIMUM_WITHDRAWAL", Number(data));
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const getEntityCard = async ({ commit }) => {
	try {
		const response = await api.getEntityCard();
		const { data } = response.data;
		commit("SET_ENTITY_CARD", data);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const deleteCard = async ({ dispatch }, payload) => {
	dispatch("setSmallLoading", true, { root: true });
	try {
		const response = await api.deleteCard(payload);
		const { message } = response.data;
		dispatch("getEntityCard");
		dispatch(
			"showToast",
			{ description: message, display: true, type: "success" },
			{ root: true }
		);
		dispatch("setSmallLoading", false, { root: true });
		return true;
	} catch (error) {
		dispatch("setSmallLoading", false, { root: true });
		responseHandler.handleError(error);
		return false;
	}
};

export const getTransactionFees = async ({ commit }) => {
	try {
		const response = await api.getTransactionFees();
		const { data } = response.data;
		commit("SET_TRANSACTION_FEES", data);
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		return error;
	}
};

export const getOrganisationPlan = async ({ commit }) => {
	return new Promise((resolve, reject) => {
		org
			.getOrganisationPlan()
			.then((response) => {
				const {
					data: { data },
				} = response;
				commit("SET_PLAN_INFO", data);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				reject(error);
			});
	});
};
export const getPlans = async ({ commit }) => {
	try {
		const response = await org.getPlans();
		const {
			data: { data },
		} = response;
		commit("SET_PLANS", data);
		return response;
	} catch (error) {
		responseHandler.handleError(error);
		return error;
	}
};

export const initiatePlan = async ({ dispatch }, payload) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.initiatePlan(payload)
			.then((response) => {
				dispatch("setLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const terminatePlan = ({ dispatch, commit }, id) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.terminatePlan(id)
			.then((response) => {
				dispatch("setLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const payForPlan = ({ dispatch }, payload) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.payForPlan(payload)
			.then((response) => {
				dispatch("setLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const addMemberToDealRoom = ({ dispatch, commit }, payload) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.addMemberToDealRoom(payload)
			.then((response) => {
				dispatch("setLoading", false, { root: true });
				commit("SET_DEALROOM_MEMBER", response.data.data);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const lookupUsername = ({}, username) => {
	return new Promise((resolve, reject) => {
		org
			.lookupUsername(username)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				reject(error);
			});
	});
};

export const deleteDocument = async ({ dispatch }, payload) => {
	try {
		const response = await org.deleteDocument(payload);
		return true;
	} catch (error) {
		responseHandler.handleError(error);
		return false;
	}
};

export const getProducts = async ({ dispatch, commit }) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.getProducts()
			.then((response) => {
				dispatch("setLoading", false, { root: true });
				const {
					data: { products },
				} = response.data;
				commit("SET_PRODUCTS", products);
				resolve(response);
			})
			.catch((error) => {
				dispatch("setLoading", false, { root: true });
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const getAllMembers = ({ dispatch, commit }, query) => {
	dispatch("setLoading", true, { root: true });
	return new Promise((resolve, reject) => {
		org
			.getAllMembers(query)
			.then((response) => {
				const {
					data: {
						data: { members, total, pages },
					},
				} = response;
				commit("SET_MEMBERS", members);
				dispatch("setLoading", false, { root: true });
				resolve(response);
			})
			.catch((error) => {
				dispatch("setLoading", false, { root: true });
				responseHandler.handleError(error);
				dispatch("setLoading", false, { root: true });
				reject(error);
			});
	});
};

export const getMinimumFunding = ({ commit }) => {
	return new Promise((resolve, reject) => {
		org
			.getMinimumFunding()
			.then((response) => {
				const { data } = response.data;
				commit("SET_MINIMUM_DEPOSIT", data);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				reject(error);
			});
	});
};

export const convertCurrency = ({ commit }, payload) => {
	return new Promise((resolve, reject) => {
		org
			.convertCurrency(payload)
			.then((response) => {
				const { amount } = response.data.data;
				commit("SET_CURRENCY_RATE", amount);
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				reject(error);
			});
	});
};

export const convertWallet = async ({}, payload) => {
	return new Promise((resolve, reject) => {
		org
			.convertWallet(payload)
			.then((response) => {
				resolve(response);
			})
			.catch((error) => {
				responseHandler.handleError(error);
				reject(error);
			});
	});
};
