import responseHandler from "@/utils/responseHandler.js";
// import api from "@/utils/api.js";
import api from "@/services/apiClient.js";

export const toggleLoading = async ({ commit }, value) => {
  commit("SET_LOADING_STATE", value);
};

export const getCustomers = async ({ commit, dispatch }, payload) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getCustomers(payload);
    const { data } = response.data;
    commit("SET_CUSTOMERS", data);
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};

export const getTotalTransactionVolume = async ({ commit, dispatch }) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getTotalTransactionVolume();
    const { data } = response.data;
    commit("SET_TOTAL_TRANSACTION_VOLUME", data);
    dispatch("setLoading", false, { root: true });
    return data;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};

export const toggleEnvironment = async ({ dispatch }, value) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.toggleEnvironment(value);
    const { data } = response.data;
    dispatch("organisationModule/setOrganisationProfile", data, {
      root: true,
    });
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};

export const getApikey = async ({ commit, dispatch }) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getApikey();
    const { data } = response.data;
    commit("SET_API_INFO", data);
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};
export const generateApikey = async ({ commit, dispatch }) => {
  dispatch("toggleLoading", true);
  try {
    const response = await api.generateApikey();
    const { data } = response.data;
    commit("SET_API_INFO", data);
    dispatch("toggleLoading", false);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("toggleLoading", false);
    return error;
  }
};

export const getApiWebHook = async ({ commit, dispatch }) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getApiWebHook();
    const { data } = response.data;
    commit("SET_API_WEBHOOK", data);
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    const { status } = error.response;
    if (status !== 404) {
      responseHandler.handleError(error);
    }
    dispatch("setLoading", false, { root: true });
    return error;
  }
};

export const addWebHook = async ({ commit, dispatch }, payload) => {
  dispatch("toggleLoading", true);
  try {
    const response = await api.addWebHook(payload);
    const { data } = response.data;
    commit("SET_API_WEBHOOK", data);
    dispatch("toggleLoading", false);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("toggleLoading", false);
    return error;
  }
};
export const updateWebhook = async ({ commit, dispatch }, payload) => {
  dispatch("toggleLoading", true);
  try {
    const response = await api.updateWebhook(payload);
    const { data } = response.data;
    commit("SET_API_WEBHOOK", data);
    dispatch("toggleLoading", false);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("toggleLoading", false);
    return error;
  }
};

export const searchCustomers = async ({ commit, dispatch }, payload) => {
  dispatch("toggleLoading", true);
  try {
    const response = await api.searchCustomers(payload);
    const { data } = response.data;
    commit("SET_CUSTOMER_SEARCH_RESULT", data);
    dispatch("toggleLoading", false);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("toggleLoading", false);
    return error;
  }
};

export const getCustomerTransactions = async ({ commit, dispatch }, email) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getCustomerTransactions(email);
    const { data } = response.data;
    commit("SET_CUSTOMER_TRANSACTIONS", data);
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};

export const getTransactions = async ({ commit, dispatch }, params) => {
  dispatch("setLoading", true, { root: true });
  try {
    const response = await api.getApiTransactions(params);
    const { data } = response.data;
    commit("SET_TRANSACTIONS", data);
    dispatch("setLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setLoading", false, { root: true });
    return error;
  }
};
