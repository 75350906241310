import axios from "./axios";
const baseUrl = `/spv`;
export default {
    fetchSpvRequests(params) {
        return axios({
            url: `${baseUrl}`,
            params,
            method: "GET",
        });
    },
    uploadSpv(spv) {
        return axios({
            url: `${baseUrl}/upload-spv`,
            method: "POST",
            data: spv,
        });
    },
    fetchSpvs(params) {
        return axios({
            url: `${baseUrl}`,
            params,
            method: "GET",
        });
    },
    requestSpv(spv) {
        return axios({
            url: `${baseUrl}/request-spv`,
            method: "POST",
            data: spv,
        });
    },
    updateSpvRequest({ id, spv }) {
        return axios({
            url: `${baseUrl}/${id}/business-info`,
            method: "PATCH",
            data: spv,
        });
    },
    fetchSpvRequest(id) {
        return axios({
            url: `${baseUrl}/${id}`,
            method: "GET",
        });
    },
    updateSpvCompliance({ id, payload }) {
        return axios({
            url: `${baseUrl}/${id}/compliance`,
            method: "PATCH",
            data: payload,
        });
    },
    searchSpvRequest(name) {
        return axios({
            url: `${baseUrl}/search/${name}`,
            method: "GET",
        });
    },
    cancelSpvRequest(id) {
        return axios({
            url: `${baseUrl}/${id}/cancel`,
            method: "PATCH",
        });
    },
    payForSpv(id) {
        return axios({
            url: `${baseUrl}/${id}/pay`,
            method: "PATCH",
        });
    },
    deleteSpv(id) {
        return axios({
            url: `${baseUrl}/${id}`,
            method: "DELETE",
        });
    },
};