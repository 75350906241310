<template>
  <Toast />
  <router-view />
</template>

<script>
  import Toast from "@/components/notification/Toast.vue";

  export default {
    name: "App",

    components: {
      Toast,
    },
  };
</script>

<style lang="scss">
  .oneroute_widget {
    .widget_container {
      .top_section {
        h3,
        h4 {
          color: #ffffff;
        }
      }
    }
  }

  input,
  button,
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  span {
    font-family: "Circular Std" !important;
  }
</style>
