export const SET_DEAL_REQUESTS = (state, payload) => {
    state.dealRequests = payload
}

export const SET_TOTAL_REQUESTS = (state, payload) => {
    state.totalRequests = payload
}

export const SET_REQUEST_PAGE = (state, payload) => {
    state.requestPage = payload
}

export const SET_REQUEST_PAGES = (state, payload) => {
    state.requestPages = payload
}

export const SET_DEAL = (state, payload) => {
    state.dealInfo = payload
}
export const SET_DEAL_MEMBERS = (state, payload) => {
    state.dealMembers = payload
}

export const SET_DEAL_INVESTORS = (state, payload) => {
    state.dealInvestors = payload
}

export const SET_DEAL_TRANSACTIONS = (state, payload) => {
    state.DealTransactions = payload
}

export const SET_TOTAL_TRANSACTION = (state, payload) => {
    state.totalTransactions = payload
}

export const SET_PAGE = (state, payload) => {
    state.page = payload
}

export const SET_PAGES = (state, payload) => {
    state.pages = payload
}

export const SET_MEMBER_QUERY = (state, payload) => {
    state.members = payload
}