import api from "@/services/spv";
import responseHandler from "@/utils/responseHandler.js";
export const createAsset = async({ commit }, asset) => {
    try {
        const response = await api.uploadSpv(asset);
        const { data } = response.data;
        commit("SET_ASSET", data);
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        return error;
    }
};

export const fetchSpvRequests = async({ commit, dispatch }, params) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.fetchSpvRequests(params);
        const { data } = response.data;
        commit("SET_SPV_REQUESTS", data);
        dispatch("setLoading", false, { root: true });
    } catch (error) {
        dispatch("setLoading", false, { root: true });
        responseHandler.handleError(error);
        return error;
    }
};

export const fetchSpvs = async({ commit, dispatch }, params) => {
    // const orgId = rootState.organisationModule.organisationDetails._id;
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.fetchSpvs(params);
        const { data } = response.data;
        commit("SET_SPVS", data);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};

export const requestSpv = async({ dispatch }, obj) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.requestSpv(obj);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        console.log({ error });
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};

export const cancelSpvRequest = async({ dispatch }, id) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.cancelSpvRequest(id);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};
export const deleteSpv = async({ dispatch }, id) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.deleteSpv(id);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};

export const updateSpvRequest = async({ dispatch }, object) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.updateSpvRequest(object);
        const { message } = response.data;
        responseHandler.handleSuccess(message);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};

export const fetchSpvRequest = async({ dispatch, commit }, id) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.fetchSpvRequest(id);
        const { data: spvInfo } = response.data;
        commit("SET_SPV_INFO", spvInfo);
        dispatch("setLoading", false, { root: true });
    } catch (error) {
        console.log({ error });
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};
export const updateSpvCompliance = async({ dispatch }, payload) => {
    dispatch("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
        api
            .updateSpvCompliance(payload)
            .then((response) => {
                const { message } = response.data;
                responseHandler.handleSuccess(message);
                dispatch("setLoading", false, { root: true });
                resolve(response);
            })
            .catch((error) => {
                responseHandler.handleError(error);
                dispatch("setLoading", false, { root: true });
                reject(error);
            });
    });
};

export const payForSpv = async({ dispatch }, id) => {
    dispatch("setLoading", true, { root: true });
    return new Promise((resolve, reject) => {
        api
            .payForSpv(id)
            .then((response) => {
                // const { message } = response.data;
                // responseHandler.handleSuccess(message);
                dispatch("setLoading", false, { root: true });
                resolve(response);
            })
            .catch((error) => {
                responseHandler.handleError(error);
                dispatch("setLoading", false, { root: true });
                reject(error);
            });
    });
};

export const searchSpvRequest = async({ dispatch, commit }, name) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.searchSpvRequest(name);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};

export const uploadSpv = async({ dispatch }, object) => {
    dispatch("setLoading", true, { root: true });
    try {
        const response = await api.uploadSpv(object);
        dispatch("setLoading", false, { root: true });
        return response;
    } catch (error) {
        responseHandler.handleError(error);
        dispatch("setLoading", false, { root: true });
        return error;
    }
};