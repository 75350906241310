export const loading = (state) => state.loading;
export const customers = (state) =>
    state.customers ? state.customers.customers : [];
export const customersPages = (state) =>
    state.customers ? state.customers.pages : 0;
export const customersCurrentPage = (state) =>
    state.customers ? state.customers.page : 1;
export const totalCustomers = (state) =>
    state.customers ? state.customers.count : 0;
export const totalTransactionVolume = (state) => state.totalTransactionVolume;
export const customerSearchResult = (state) => state.customerSearchResult;
export const apiKeyInfo = (state) => state.apiKeyInfo;
export const webhook = (state) => state.webhook;
export const customerTransactions = (state) =>
    state.customerTransactions ? state.customerTransactions.transactions : [];
export const totalCustomerTransactions = (state) =>
    state.customerTransactions ? state.customerTransactions.total : 0;
export const customerTransactionsPages = (state) =>
    state.customerTransactions ? state.customerTransactions.pages : 0;
export const customerTransactionPage = (state) =>
    state.customerTransactions ? state.customerTransactions.page : 1;
export const transactions = (state) =>
    state.transactions ? state.transactions.transactions : [];
export const totalTransactions = (state) =>
    state.transactions ? state.transactions.total : 0;
export const transactionsPages = (state) =>
    state.transactions ? state.transactions.pages : 0;
export const transactionPage = (state) =>
    state.transactions ? state.transactions.page : 1;