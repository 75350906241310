export const SHOW_TOAST = (state, payload) => {
  state.toast = payload;
};

export const SET_LOADING = (state, payload) => {
  state.loading = payload;
};

export const SET_SMALL_LOADING = (state, payload) => {
  state.smallLoading = payload;
};

export const SET_CAROUSEL = (state, payload) => {
  state.carousel = payload;
};
