import { getItem } from "@/utils/storage.js";
import axios from "axios";

const baseUrl = `${process.env.VUE_APP_BASE_URL}`;
const organisationUrl = `${baseUrl}/organisation`;
const userUrl = `${baseUrl}/user`;
const tokenUrl = `${baseUrl}/tokens`;
const transactionUrl = `${baseUrl}/transaction`;

//https://ge-exchange-staging-1.herokuapp.com/v1/token/622f8b3599482e00041b2702/buy

export default {
  getHeader: () => {
    let headers = {
      Authorization: "Bearer " + getItem("org_token"),
    };
    return headers;
  },

  getOrganisationProfile(id) {
    return axios.get(`${organisationUrl}/${id}`, {
      headers: this.getHeader(),
    });
  },

  getOrganisationOverview() {
    return axios.get(`${organisationUrl}/overview`, {
      headers: this.getHeader(),
    });
  },

  getTransactions(query) {
    return axios.get(`${transactionUrl}/organisation?`, {
      params: {
        page: query.page,
        limit: query.limit,
        status: query.status,
      },
      headers: this.getHeader(),
    });
  },

  fetchMemberByName(query) {
    return axios.get(`${organisationUrl}/member/search?param=${query}`, {
      headers: this.getHeader(),
    });
  },

  inviteMember(obj) {
    return axios({
      url: `${baseUrl}/deal/${obj.id}/invite-members`,
      method: "POST",
      data: {
        deal_access: "Private",
        members: obj.members,
      },
      headers: this.getHeader(),
    });
  },

  generateInviteLink(data) {
    return axios.post(`${baseUrl}/invite-link`, data, {
      headers: this.getHeader(),
    });
  },

  viewInviteLink() {
    return axios.get(`${baseUrl}/invite-link`, {
      headers: this.getHeader(),
    });
  },

  deactivateInviteLink(id) {
    return axios.delete(`${baseUrl}/invite-link/${id}`, {
      headers: this.getHeader(),
    });
  },

  updateOrganisationDetails(data) {
    return axios.patch(`${organisationUrl}`, data, {
      headers: this.getHeader(),
    });
  },

  sendToken(data) {
    return axios.patch(`${organisationUrl}/send-token`, data, {
      headers: this.getHeader(),
    });
  },

  submitDocument(data) {
    const type = data.documents[0].type;
    return axios.post(`${baseUrl}/kyc/documents/${type}`, data, {
      headers: this.getHeader(),
    });
  },

  uploadSelfie(selfie) {
    return axios({
      url: "kyc/selfie",
      data: selfie,
      method: "POST",
      headers: this.getHeader(),
    });
  },

  uploadSpv({ type, spv, dealId }) {
    return axios({
      url: `${baseUrl}/token-request/${dealId}/add-spv`,
      method: "POST",
      data: spv,
      headers: this.getHeader(),
    });
  },

  getWalletTransaction(query) {
    return axios.get(`${transactionUrl}/wallet`, {
      params: {
        page: query.page,
        type: query.type,
        limit: query.limit,
      },
      headers: this.getHeader(),
    });
  },

  getBankDetails() {
    return axios.get(`${baseUrl}/bank`, {
      headers: this.getHeader(),
    });
  },

  getWalletBalance() {
    return axios.get(`${baseUrl}/token/currency-balance`, {
      headers: this.getHeader(),
    });
  },

  withdrawFees() {
    return axios.get(`${baseUrl}/withdrawal-requests/fees`, {
      headers: this.getHeader(),
    });
  },

  fundWithProvider(data) {
    return axios.post(`${baseUrl}/payments/fund/${data.type}`, data.data, {
      headers: this.getHeader(),
    });
  },
  getCurrencies() {
    return axios.get(`${baseUrl}/payments/currencies`, {
      headers: this.getHeader(),
    });
  },

  getExchangeRate(query) {
    return axios.get(`${baseUrl}/payments/fx-rate`, {
      params: {
        from: query.from,
        to: query.to,
      },
      headers: this.getHeader(),
    });
  },

  createWithdrawalRequest(data) {
    return axios.post(`${baseUrl}/withdrawal-requests`, data, {
      headers: this.getHeader(),
    });
  },
  fetchSpv(id) {
    return axios({
      url: `${baseUrl}/spv/${id}`,
      method: "GET",
      headers: this.getHeader(),
    });
  },
  requestSpv({ request, id }) {
    return axios({
      url: `${baseUrl}/token-request/${id}/request-spv`,
      method: "POST",
      data: request,
      headers: this.getHeader(),
    });
  },
  updateSpv({ request, id }) {
    return axios({
      url: `${baseUrl}/spv/${id}`,
      method: "PATCH",
      data: request,
      headers: this.getHeader(),
    });
  },

  deactivateMember(memberId) {
    return axios({
      url: `${organisationUrl}/members/${memberId}/deactivate`,
      method: "PATCH",
      headers: this.getHeader(),
    });
  },

  activateMember(memberId) {
    return axios({
      url: `${organisationUrl}/members/${memberId}/activate`,
      method: "PATCH",
      headers: this.getHeader(),
    });
  },

  removeMember(memberId) {
    // from a specific deal
    return axios({
      url: `${baseUrl}/deal-member/${memberId}/remove-member`,
      method: "DELETE",
      headers: this.getHeader(),
    });
  },

  transferToken(obj) {
    return axios({
      url: `${baseUrl}/token/transfer`,
      method: "POST",
      data: obj,
      headers: this.getHeader(),
    });
  },

  getNotifications(query) {
    return axios.get(`${baseUrl}/notifications`, {
      params: {
        page: query.page,
        limit: query.limit,
      },
      headers: this.getHeader(),
    });
  },

  getTotalUnreadNotifications() {
    return axios.get(`${baseUrl}/notifications/total-unread`, {
      headers: this.getHeader(),
    });
  },

  pendingInvite(param) {
    return axios.post(
      `${baseUrl}/pending-invite/${param.id}/${param.action}`,
      {},
      {
        headers: this.getHeader(),
      }
    );
  },

  getApprovedDeals() {
    return axios.get(`${baseUrl}/token/organisation`, {
      headers: this.getHeader(),
    });
  },

  upgradePlan(payload) {
    return axios({
      url: `${baseUrl}/plans/initiate/flutterwave`,
      method: "POST",
      headers: this.getHeader(),
      data: payload,
    });
  },

  mailList(data) {
    return axios.post(`${baseUrl}/mailchimp`, data, {
      headers: this.getHeader(),
    });
  },

  getTokens(query) {
    return axios.get(`${userUrl}/tokens-paginated`, {
      params: {
        page: query.page,
        limit: query.limit,
      },
      headers: this.getHeader(),
    });
  },

  getTokenOrders(query) {
    return axios.get(`${baseUrl}/orders/token/${query.id}`, {
      params: {
        type: query.data.type || null,
        page: query.data.page || null,
        limit: query.data.limit || null,
        status: query.data.status || null,
      },
      headers: this.getHeader(),
    });
  },

  cancelOrder(id) {
    return axios.post(
      `${baseUrl}/order/cancel/${id}`,
      {},
      {
        headers: this.getHeader(),
      }
    );
  },

  generateVirtualAccount(payload) {
    return axios.post(
      `${baseUrl}/virtual-bank-accounts`,
      payload,
      {
        headers: this.getHeader(),
      }
    );
  },

  getVirtualAccount(payload) {
    return axios.get(`${baseUrl}/virtual-bank-accounts`, {
      params: {
        currency: payload,
      },
      headers: this.getHeader(),
    });
  },

  getVirtualAccountFee() {
    return axios.get(`${baseUrl}/virtual-accounts/fee`, {
      headers: this.getHeader(),
    });
  },

  tradeToken({ id, amount, type, price }) {
    return axios.post(
      `${baseUrl}/token/${id}/${type}`,
      {
        amount,
        price,
      },
      {
        headers: this.getHeader(),
      }
    );
  },

  getMinimumWithdrawal(params) {
    return axios.get(`${baseUrl}/payments/minimum-withdrawal`, {
      params,
      headers: this.getHeader(),
    });
  },

  withdrawFees() {
    return axios.get(`${baseUrl}/withdrawal-requests/fees`, {
      headers: this.getHeader(),
    });
  },

  getEntityCard() {
    return axios.get(`${baseUrl}/cards`, {
      headers: this.getHeader(),
    });
  },

  deleteCard(id) {
    return axios.delete(`${baseUrl}/cards/${id}`, {
      headers: this.getHeader(),
    });
  },

  withdrawToTradeWallet(data) {
    return axios.post(`${baseUrl}/wallet/convert-cash`, data, {
      headers: this.getHeader(),
    });
  },
  getTransactionFees() {
    return axios.get(`${baseUrl}/transaction/fee`, {
      headers: this.getHeader(),
    });
  },
  getCarousel() {
    return axios.get(`${baseUrl}/carousels`, { headers: this.getHeader() });
  },
};
