import axios from "./axios";
const organisationUrl = `/organisation`;
export default {
    loginOrganisation(data) {
        return axios({
            url: `/dealroom/login`,
            data,
            method: "POST",
        });
    },

    createOrganisation(data) {
        return axios({
            url: `${organisationUrl}`,
            data,
            method: "POST",
        });
    },

    verifyOrganisation(data) {
        return axios({
            url: `${organisationUrl}/verify`,
            data,
            method: "PATCH",
        });
    },

    resetPasswordLink(data) {
        return axios({
            url: `${organisationUrl}/initiate-password-reset`,
            data,
            method: "POST",
        });
    },

    resetPassword(data) {
        return axios({
            url: `/user/reset-pass`,
            data,
            method: "PATCH",
        });
    },

    resetPin(data) {
        return axios({
            url: `${organisationUrl}/reset-pin`,
            data,
            method: "POST",
        });
    },

    createPin(data) {
        return axios({
            url: `${organisationUrl}/create-pin`,
            data,
            method: "POST",
        });
    },

    verifyPin(pin) {
        return axios({
            url: `${organisationUrl}/verify-pin`,
            data: pin,
            method: "POST",
        });
    },
};