export default {
    organisationDetails: {},
    overviewDeals: null,
    overviewMembers: null,
    overviewTokens: null,
    orgTransactions: [],
    walletTransactions: [],
    notificationCount: 0,
    totalTransactions: 0,
    page: 0,
    pages: 0,
    walletPage: 0,
    walletPages: 0,
    walletBalance: 0,
    tradeBalance: 0,
    exchangeRate: 0,
    minimumWithdrawal: 0,
    pin: 0,
    virtualAccount: {},
    currency: "",
    fundMethod: "",
    withdrawMethod: "",
    entityCard: [],
    cardDetails: {},
    transactionFees: null,
    newDealRoomMember: {},
    getequityProducts: null,
    plans: null,
    plan: null,
    members: null,
    totalMembers: null,
    minimumDeposit: null,
    currencyRate: null
};