import api from "@/services/deal";
import responseHandler from "@/utils/responseHandler.js";

export const getDealRequests = async ({ commit }, query) => {
  try {
    const response = await api.getDealRequests(query);
    const { requests, total, page, pages } = response.data.data;
    commit("SET_DEAL_REQUESTS", requests);
    commit("SET_TOTAL_REQUESTS", total);
    commit("SET_REQUEST_PAGE", page);
    commit("SET_REQUEST_PAGES", pages);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const createDealRequest = async ({ commit }, request) => {
  try {
    const response = await api.createDealRequest(request);
    const { data } = response.data;
    commit("SET_DEAL", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
export const updateDealRequest = ({ dispatch }, request) => {
  dispatch("setSmallLoading", true, { root: true });
  return new Promise((resolve, reject) => {
    api
      .updateDealRequest(request)
      .then((response) => {
        dispatch("setSmallLoading", false, { root: true });
        resolve(response);
      })
      .catch((error) => {
        responseHandler.handleError(error);
        dispatch("setSmallLoading", false, { root: true });
        reject(error);
      });
  });
};

export const fetchDealInfo = async ({ commit }, dealId) => {
  try {
    const response = await api.fetchDealInfo(dealId);
    const { data } = response.data;
    commit("SET_DEAL", data);
    commit("Asset/SET_ASSET", data.asset, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const setDealInfo = ({ commit }, deal) => {
  commit("SET_DEAL", deal);
};

export const fetchDealMembers = async ({ commit }, dealId) => {
  try {
    const response = await api.fetchDealMembers(dealId);
    const { data } = response.data;
    commit("SET_DEAL_MEMBERS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
export const fetchDealInvestors = async ({ commit }, dealId) => {
  try {
    const response = await api.fetchDealInvestors(dealId);
    const { data } = response.data;
    commit("SET_DEAL_INVESTORS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const fetchMemberByName = async ({ commit }, name) => {
  try {
    const response = await api.fetchMemberByName(name);
    const { data } = response.data;
    commit("SET_MEMBER_QUERY", data);
  } catch (error) {
    responseHandler.handleError(error);
  }
};

export const inviteMember = async ({}, obj) => {
  try {
    const response = await api.inviteMember(obj);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
  }
};

export const deactivateMember = async ({}, obj) => {
  try {
    const response = await api.deactivateMember(obj);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
export const activateMember = async ({}, obj) => {
  try {
    const response = await api.activateMember(obj);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const removeMember = async ({}, obj) => {
  try {
    const response = await api.removeMember(obj);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const fetchDealTransactions = async ({ commit, dispatch }, obj) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.fetchDealTransactions(obj);
    const { transactions, total, page, pages } = response.data.data;
    commit("SET_DEAL_TRANSACTIONS", transactions);
    commit("SET_TOTAL_TRANSACTION", total);
    commit("SET_PAGE", page);
    commit("SET_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setSmallLoading", false, { root: true });
    return error;
  }
};

export const transferToken = async ({}, obj) => {
  try {
    const response = await api.transferToken(obj);

    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const addDealUpdate = ({ dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  return new Promise((resolve, reject) => {
    api
      .addDealUpdate(payload)
      .then((response) => {
        dispatch("setSmallLoading", false, { root: true });
        resolve(response);
      })
      .catch((error) => {
        dispatch("setSmallLoading", false, { root: true });
        responseHandler.handleError(error);
        reject(error);
      });
  });
};
