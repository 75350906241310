export const SET_ORGANISATION_DETAILS = (state, payload) => {
    state.organisationDetails = payload;
};

export const SET_PIN = (state, payload) => {
    state.pin = payload;
};

export const SET_OVERVIEW_DEALS = (state, overviewDeals) => {
    state.overviewDeals = overviewDeals;
};
export const SET_OVERVIEW_MEMBERS = (state, overviewMembers) => {
    state.overviewMembers = overviewMembers;
};
export const SET_OVERVIEW_TOKENS = (state, overviewTokens) => {
    state.overviewTokens = overviewTokens;
};

export const SET_ORGANISATION_TRANSACTION = (state, payload) => {
    state.orgTransactions = payload;
};

export const SET_WALLET_TRANSACTION = (state, payload) => {
    state.walletTransactions = payload;
};

export const SET_WALLET_TRANSACTION_PAGE = (state, payload) => {
    state.walletPage = payload;
};

export const SET_WALLET_TRANSACTION_PAGES = (state, payload) => {
    state.walletPages = payload;
};

export const SET_TOTAL_TRANSACTION = (state, payload) => {
    state.totalTransactions = payload;
};

export const SET_EXCHANGE_RATE = (state, payload) => {
    state.exchangeRate = payload;
};

export const SET_PAGE = (state, payload) => {
    state.page = payload;
};

export const SET_PAGES = (state, payload) => {
    state.pages = payload;
};

export const SET_WALLET_BALANCE = (state, payload) => {
    state.walletBalance = payload;
};

export const SET_TRADING_BALANCE = (state, payload) => {
    state.tradeBalance = payload;
};

export const SET_NOTIFICATION_COUNT = (state, payload) => {
    state.notificationCount = payload;
};

export const SET_VIRTUAL_ACCOUNT = (state, payload) => {
    state.virtualAccount = payload;
};

export const SET_CURRENCY = (state, payload) => {
    state.currency = payload;
};

export const SET_WITHDRAW_METHOD = (state, payload) => {
    state.withdrawMethod = payload;
};

export const SET_FUND_METHOD = (state, payload) => {
    state.fundMethod = payload;
};

export const SET_MINIMUM_WITHDRAWAL = (state, payload) => {
    state.minimumWithdrawal = payload;
};

export const SET_ENTITY_CARD = (state, payload) => {
    state.entityCard = payload;
};

export const SET_CARD_DETAILS = (state, payload) => {
    state.cardDetails = payload;
};
export const SET_TRANSACTION_FEES = (state, payload) => {
    state.transactionFees = payload;
};
export const SET_DEALROOM_MEMBER = (state, payload) => {
    state.newDealRoomMember = payload;
};
export const SET_PRODUCTS = (state, payload) => {
    state.getequityProducts = payload;
};

export const SET_PLANS = (state, plans) => {
    state.plans = plans;
};

export const SET_PLAN_INFO = (state, plan) => {
    state.plan = plan;
};

export const SET_MEMBERS = (state, members) => {
    state.members = members;
};

export const SET_TOTAL_MEMBERS = (state, totalMembers) => {
    state.totalMembers = totalMembers;
};

export const SET_MINIMUM_DEPOSIT = (state, payload) => {
    state.minimumDeposit = payload;
}

export const SET_CURRENCY_RATE = (state, balances) => {
    state.currencyRate = balances;
};