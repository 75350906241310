import axios from "./axios";
const baseUrl = `/asset`;
export default {
  createAsset(asset) {
    return axios({
      url: `${baseUrl}`,
      data: asset,
      method: "POST",
    });
  },

  getCompaniesRaising(query) {
    return axios({
      url: "/tokens/raising",
      params: {
        page: query.page,
        limit: query.limit,
      },
      method: "GET",
    });
  },

  getCompaniesRaised(query) {
    return axios({
      url: "/tokens/raised",
      params: {
        page: query.page,
        limit: query.limit,
      },
      method: "GET",
    });
  },

  getOrderBooks(query) {
    return axios({
      url: "/order/user",
      params: {
        tokenId: query.id,
        type: query.type,
        page: query.page,
        limit: query.limit,
        status: query.status,
      },
      method: "GET",
    });
  },

  cancelOrder(id) {
    return axios({
      url: `/order/cancel/${id}`,
      method: "POST",
    });
  },

  getSingleToken(id) {
    return axios({
      url: `/token/${id}`,
      method: "GET",
    });
  },

  getTokenBalance(tokenSymbol) {
    return axios({
      url: `/token/${tokenSymbol}/balance`,
      method: "GET",
    });
  },

  getTokenUpdate(query) {
    return axios({
      url: `/token/${query.id}/updates`,
      params: {
        page: query.page,
        limit: query.limit,
      },
      method: "GET",
    });
  },

  updateAsset({ asset, assetId }) {
    return axios({
      url: `${baseUrl}/${assetId}`,
      data: asset,
      method: "PATCH",
    });
  },
  deleteFounder({ id, asset }) {
    return axios({
      url: `/founders/${id}`,
      method: "DELETE",
      data: { asset },
    });
  },
  searchAsset(name) {
    return axios({
      url: `${baseUrl}/search?name=${name}`,
      method: "GET",
    });
  },

  fetchAsset(assetId) {
    return axios({
      url: `${baseUrl}/${assetId}`,
      method: "GET",
    });
  },

  fetchAssetDraft() {
    return axios({
      url: `${baseUrl}/drafts`,
      method: "GET",
    });
  },

  getTokens(query) {
    return axios({
      url: "/user/tokens-paginated",
      method: "GET",
      params: {
        page: query.page,
        limit: query.limit,
      },
    });
  },
};
