export function isNumber(evt) {
  evt = evt ? evt : window.event;
  let charCode = evt.which ? evt.which : evt.keyCode;
  if (
    charCode > 31 &&
    (charCode < 48 || charCode > 57) &&
    charCode !== 46 &&
    charCode !== 109
  ) {
    evt.preventDefault();
  } else {
    return true;
  }
}

export function strip(formattedNumber) {
  return String(formattedNumber)
    .split(",")
    .join("");
}

export function formatAmountToDollar(amount, decimal, currency) {
  if (amount === undefined || currency === "") return;
  const value = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
    minimumFractionDigits: decimal,
  });
  return value.format(amount);
}

export function formatISODate(date) {
  if (!date) return "";
  let currentdate = new Date(date);

  let datetime =
    currentdate.getDate() +
    "-" +
    (currentdate.getMonth() + 1) +
    "-" +
    currentdate.getFullYear();

  return datetime;
}

export function formatToStringDate(date) {
  if (!date) return "";
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let currentdate = new Date(date);

  let datetime =
    days[currentdate.getDay()] +
    ", " +
    currentdate.getDate() +
    " " +
    months[currentdate.getMonth()] +
    " " +
    currentdate.getFullYear();

  return datetime;
}

export function formatISOTime(date) {
  return new Date(date).toLocaleString("en-US").split(",")[1];
}

export function milliSecondsToDayHourMin(timeRemaining) {
  let dayInMilliseconds = 86400000;
  let hourInMilliseconds = 3600000;
  let day = Math.floor(timeRemaining / dayInMilliseconds);
  let hour = Math.floor(
    (timeRemaining - day * dayInMilliseconds) / hourInMilliseconds
  );
  let min = Math.round(
    (timeRemaining - day * dayInMilliseconds - hour * hourInMilliseconds) /
      60000
  );
  let seconds = Math.round(timeRemaining - min / 60);
  const pad = function(num) {
    return num < 10 ? "0" + num : num;
  };
  if (min === 60) {
    hour++;
    min = 0;
  }
  if (hour === 24) {
    day++;
    hour = 0;
  }
  return `${day} : ${pad(hour)}  :  ${pad(min)}:  ${pad(seconds)}`;
}

export function calculateDay(time) {
  let timeToMilliseconds = new Date(time).getTime();
  let currentTime = Date.now();
  let ellapsedTime = currentTime - timeToMilliseconds;
  const days = Math.floor(ellapsedTime / (1000 * 60 * 60 * 24));
  return days;
}

export function calculateHour(time) {
  let timeToMilliseconds = new Date(time).getTime();
  let currentTime = Date.now();
  let ellapsedTime = currentTime - timeToMilliseconds;
  const hour = Math.floor(ellapsedTime / (1000 * 60 * 60)) % 24;
  return hour;
}

export function calculateMin(time) {
  let timeToMilliseconds = new Date(time).getTime();
  let currentTime = Date.now();
  let ellapsedTime = currentTime - timeToMilliseconds;
  const min = Math.floor(ellapsedTime / (1000 * 60)) % 60;
  return min;
}

export function getTimeLeft(countDownDate) {
  const now = new Date().getTime();

  // Find the distance between now and the count down date
  const distance = countDownDate - now;
  if (distance < 0) return "EXPIRED";
  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((distance % (1000 * 60)) / 1000);
  const pad = function(num) {
    return num < 10 ? "0" + num : num;
  };
  return `${days} : ${pad(hours)}  :  ${pad(minutes)}:  ${pad(seconds)}`;
}
export function capitalizeEachWord(string) {
  if (!string) return;
  let words = string.split(" ").map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });
  return words.join(" ");
}

export function emailUrl() {
  let originUrl = null;
  switch (window.location.host) {
    case "dealroom.getequity.io":
      originUrl = "https://app.getequity.io";
      break;
    case "dev--dealroom.netlify.app":
      originUrl = "https://dev--dealroom.netlify.app/";
      break;
    case "institutional-merge-dev--dealroom.netlify.app":
      originUrl = "https://institutional-merge-dev--dealroom.netlify.app/";
      break;
    case "institutional-merge-staging--dealroom.netlify.app":
      originUrl = "https://institutional-merge-staging--dealroom.netlify.app/";
      break;
    default:
      originUrl = "https://staging--dealroom.netlify.app/";
      break;
  }
  return originUrl;
}

export function abbreviate(string) {
  if (!string) return;
  let letters = string.split(" ").map((word) => {
    return word.charAt(0).toUpperCase();
  });
  return letters.join(" . ");
}

export function capitalize(string) {
  if (!string) return;
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncate(text, length) {
  if (text.length > length) {
    return text.slice(0, length) + "...";
  } else {
    return text;
  }
}

export function removeUnderscore(value) {
  if (!value) return "";
  if (!value.includes("_")) return value;
  const splitValue = value.split("_");
  if (splitValue.length === 1) return splitValue;
  return splitValue.join(" ");
}

export function getRandomColour() {
  const activityColours = [
    "#3C7CBC",
    "#00BB5D",
    "#F7B633",
    "#070E14",
    "#DA0A0A",
  ];
  const number = Math.floor(Math.random() * activityColours.length);
  return activityColours[number];
}

export function convertToLocale(number) {
  return number.toLocaleString();
}

export function blankString(str) {
  return !str || /^\s*$/.test(str);
}

export const industries = [
  "Electronics",
  "Mining",
  "Music",
  "Manufacturing",
  "Energy",
  "News Media",
  "Entertainment",
  "Hospitality",
  "Health care",
  "Food",
  "Pharmaceutical",
  "Education",
  "Commodities",
  "Construction",
  "Agriculture",
  "Telecommunication",
  "Technology",
  "Computer and Technology",
  "Transport",
  "Aerospace",
  "Retail",
  "Financial Services",
  "Supply Chain",
  "Logistics",
  "Legal",
  "Real Estate",
  "Others",
].sort();

export const stages = [
  "Pre seed",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Public",
];

export const companyTypes = ["Public", "Private", "Non Profit"];

export function getValueType(param) {
  if (typeof param === "string") {
    return 'string'
  } else if (typeof param === "object" && param !== null) {
    return 'object'
  } else {
    console.log("Parameter is neither an object nor a string");
  }
}
