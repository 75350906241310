import axios from "./axios";
const organisationUrl = `/organisation`;
export default {
  getOrganisationProfile(id) {
    return axios({
      url: `${organisationUrl}/${id}`,
      method: "GET",
    });
  },
  getOrganisationInfo() {
    return axios({
      url: "organisations/me",
      method: "GET",
    });
  },

  getOrganisationOverview() {
    return axios({
      url: `${organisationUrl}/overview`,
      method: "GET",
    });
  },

  updateOrganisationDetails(data) {
    return axios({
      url: `${organisationUrl}`,
      data,
      method: "PATCH",
    });
  },

  uploadSelfie(selfie) {
    return axios({
      url: "/kyc/selfie",
      data: selfie,
      method: "POST",
    });
  },

  getOrganisationPlan() {
    return axios({
      url: `/plans/organisation`,
      method: "GET",
    });
  },

  getPlans() {
    return axios({
      url: "/plans/types",
      method: "GET",
    });
  },
  initiatePlan(payload) {
    return axios({
      url: "/plans/initiate",
      method: "POST",
      data: payload,
    });
  },
  payForPlan(payload) {
    return axios({
      url: "/plans/pay",
      method: "POST",
      data: payload,
    });
  },
  terminatePlan(id) {
    return axios({
      url: `/plans/${id}`,
      method: "DELETE",
    });
  },

  submitDocument(data) {
    const type = data.documents[0].type;
    return axios({
      url: `/kyc/documents/${type}`,
      data,
      method: "POST",
    });
  },
  submitOtherDocument(data) {
    const docType = data.documents[0].type;
    const type = data.type;
    return axios({
      url: `/documents?type=${type}&doc_type=${docType}`,
      data: { documents: data.documents },
      method: "POST",
    });
  },
  updateOtherDocument(data) {
    const docType = data.documents[0].type;
    const type = data.type;
    return axios({
      url: `/documents?type=${type}&doc_type=${docType}`,
      data: { documents: data.documents },
      method: "PATCH",
    });
  },
  fetchSubscriptions() {
    return axios({
      url: `${organisationUrl}/subscription-fees`,
      method: "GET",
    });
  },

  updateSubscription(subscription) {
    return axios({
      url: `${organisationUrl}/subscription-fees`,
      method: "PATCH",
      data: subscription,
    });
  },

  fetchBankAccounts() {
    return axios({
      url: `/bank`,
      method: "GET",
    });
  },

  addBankAccount(bank) {
    return axios({
      url: `/bank`,
      method: "POST",
      data: bank,
    });
  },

  deleteBank(bank) {
    return axios({
      url: `/bank/${bank}`,
      method: "DELETE",
    });
  },

  getApprovedDeals() {
    return axios({
      url: `${organisationUrl}/token`,
      method: "GET",
    });
  },

  searchMembers(param) {
    return axios({
      url: `${organisationUrl}/member/search`,
      method: "GET",
      params: {
        param: param,
      },
    });
  },

  getWaitlistMembers(query) {
    return axios({
      url: "/invite-link/pending",
      method: "GET",
      params: {
        page: query.page,
        limit: query.limit,
      },
    });
  },

  getAllMembers(query) {
    let params = {};
    if (query.status === "") {
      params = {
        page: query.page,
        limit: query.limit,
      };
    } else {
      params = {
        page: query.page,
        limit: query.limit,
        status: query.status,
      };
    }
    return axios({
      url: `${organisationUrl}/members`,
      method: "GET",
      params,
    });
  },
  addMemberToDealRoom({ payload, id }) {
    return axios({
      url: `${organisationUrl}/${id}/member`,
      method: "POST",
      data: payload,
    });
  },
  lookupUsername(username) {
    return axios({
      url: "username-lookup",
      params: { username },
      method: "GET",
    });
  },
  addMemberBankAccount(bank) {
    return axios({
      url: `/organisation/member/bank`,
      method: "POST",
      data: bank,
    });
  },
  deleteDocument(payload) {
    return axios({
      url: `/documents/delete/${payload.docId}`,
      method: "POST",
      data: { type: payload.type },
    });
  },
  getProducts() {
    return axios({
      url: "products",
      method: "GET",
    });
  },
  removeMember(id) {
    return axios({
      // from the dealroom
      url: `/organisation/members/${id}/remove`,
      method: "DELETE",
    });
  },
  getCompanyUpdates(id) {
    return axios({
      url: `/organisation-update/${id}`,
      method: "GET",
    });
  },
  convertWallet(payload) {
    return axios({
      url: `/wallet/convert-wallet`,
      data: payload,
      method: 'POST'
    })
  },
  convertCurrency(params) {
    return axios({
      url: `/payments/fx-amount`,
      params,
      method: 'GET'
    })
  },

  getMinimumFunding() {
    return axios({
      url: '/payments/minimum-deposit',
      method: 'GET'
    });
  },
  
};
