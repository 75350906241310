export default {
  asset: {},
  assets: null,
  draftAssets: null,
  tokens: [],
  token: [],
  companiesRaising: [],
  tokenUpdate: [],
  tokenUpdatePage: 0,
  tokenUpdatePages: 0,
  recentRaising: [],
  raisingPage: 0,
  raisingPages: 0,
  companiesRaised: [],
  raisedPage: 0,
  raisedPages: 0,
  orderBook: [],
  buyOrderBook: [],
  sellOrderBook: [],
  totalOrder: 0,
  orderPage: 0,
  orderPages: 0,
  singleTokenBalance: 0,
  tokenPage: 0,
  tokenPages: 0,
};
