export const SET_LOADING_STATE = (state, payload) => {
    state.loading = payload;
};
export const SET_CUSTOMERS = (state, payload) => {
    state.customers = payload;
};
export const SET_TOTAL_TRANSACTION_VOLUME = (state, payload) => {
    state.totalTransactionVolume = payload;
};
export const SET_API_INFO = (state, payload) => {
    state.apiKeyInfo = payload;
};
export const SET_API_WEBHOOK = (state, payload) => {
    state.webhook = payload;
};
export const SET_CUSTOMER_SEARCH_RESULT = (state, payload) => {
    state.customerSearchResult = payload;
};
export const SET_CUSTOMER_TRANSACTIONS = (state, payload) => {
    state.customerTransactions = payload;
};
export const SET_TRANSACTIONS = (state, payload) => {
    state.transactions = payload;
};