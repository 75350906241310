export const SET_ASSET = (state, asset) => {
  state.asset = asset;
};

export const SET_ASSETS = (state, assets) => {
  state.assets = assets;
};

export const SET_ASSET_DRAFTS = (state, draftAssets) => {
  state.draftAssets = draftAssets;
};

export const SET_USER_TOKEN = (state, payload) => {
  state.tokens = payload;
};

export const SET_TOKEN_PAGE = (state, payload) => {
  state.tokenPage = payload;
};

export const SET_TOKEN_PAGES = (state, payload) => {
  state.tokenPages = payload;
};

export const SET_SINGLE_TOKEN = (state, payload) => {
  state.token = payload;
};

export const SET_TOKEN_BALANCE = (state, payload) => {
  state.singleTokenBalance = payload;
};

export const SET_COMPANIES_RAISING = (state, payload) => {
  state.companiesRaising = payload;
};

export const SET_TOKEN_UPDATE = (state, payload) => {
  state.tokenUpdate = payload;
};

export const SET_TOKEN_UPDATE_PAGE = (state, payload) => {
  state.tokenUpdatePage = payload;
};

export const SET_TOKEN_UPDATE_PAGES = (state, payload) => {
  state.tokenUpdatePages = payload;
};

export const SET_RECENT_RAISING = (state, payload) => {
  state.recentRaising = payload;
};

export const SET_RAISING_PAGE = (state, payload) => {
  state.raisingPage = payload;
};

export const SET_RAISING_PAGES = (state, payload) => {
  state.raisingPages = payload;
};

export const SET_COMPANIES_RAISED = (state, payload) => {
  state.companiesRaised = payload;
};

export const SET_RAISED_PAGE = (state, payload) => {
  state.raisedPage = payload;
};

export const SET_RAISED_PAGES = (state, payload) => {
  state.raisedPages = payload;
};

export const SET_ORDER_BOOK = (state, payload) => {
  state.orderBook = payload;
};

export const SET_BUY_ORDER_BOOK = (state, payload) => {
  state.buyOrderBook = payload;
};

export const SET_SELL_ORDER_BOOK = (state, payload) => {
  state.sellOrderBook = payload;
};

export const SET_TOTAL_ORDERS = (state, payload) => {
  state.totalOrder = payload;
};

export const SET_ORDER_PAGE = (state, payload) => {
  state.orderPage = payload;
};

export const SET_ORDER_PAGES = (state, payload) => {
  state.orderPages = payload;
};
