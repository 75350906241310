import { clearStorage } from "@/utils/storage";
import router from "@/router";
import api from "@/utils/api";
import authApi from "@/services/auth";
import responseHandler from "@/utils/responseHandler.js";

export const showToast = ({ commit }, payload) => {
    commit("SHOW_TOAST", payload);
};

export const setLoading = ({ commit }, payload) => {
    commit("SET_LOADING", payload);
};

export const logout = () => {
    clearStorage();
    router.push("/onboarding/signin");
};

export const verifyPin = ({}, pin) => {
    return new Promise((resolve, reject) => {
        authApi
            .verifyPin(pin)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                responseHandler.handleError(error);
                reject(error);
            });
    });
};

export const setSmallLoading = ({ commit }, payload) => {
    commit("SET_SMALL_LOADING", payload);
};

export const getCarousel = async({ commit }) => {
    const response = await api.getCarousel();
    const { data } = response.data;
    commit("SET_CAROUSEL", data);
};