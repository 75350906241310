import axios from "./axios";
const tokenReqBaseUrl = "/token-requests";
const organisationUrl = `/organisation`;
export default {
  getDealRequests(params) {
    return axios({
      url: `${tokenReqBaseUrl}`,
      params: params,
      method: "GET",
    });
  },
  createDealRequest(request) {
    return axios({
      url: `${tokenReqBaseUrl}`,
      data: request,
      method: "POST",
    });
  },

  updateDealRequest({ deal, id }) {
    return axios({
      url: `${tokenReqBaseUrl}/${id}`,
      data: deal,
      method: "PATCH",
    });
  },

  fetchDealInfo(dealId) {
    return axios({
      url: `${tokenReqBaseUrl}/${dealId}`,
      method: "GET",
    });
  },

  uploadSpv({ type, spv, dealId }) {
    return axios({
      url: `${tokenReqBaseUrl}/${dealId}/add-spv`,
      method: "POST",
      data: spv,
    });
  },
  requestSpv({ request, id }) {
    return axios({
      url: `${tokenReqBaseUrl}/${id}/request-spv`,
      method: "POST",
      data: request,
    });
  },
  fetchDealMembers(dealId) {
    return axios({
      url: `/deal/${dealId}/members`,
      method: "GET",
    });
  },
  fetchDealInvestors(dealId) {
    return axios({
      url: `/token/${dealId}/investors`,
      method: "GET",
    });
  },

  fetchMemberByName(query) {
    return axios({
      url: `${organisationUrl}/member/search?param=${query}`,
      method: "GET",
    });
  },

  inviteMember(obj) {
    return axios({
      url: `/deal/${obj.id}/invite-members`,
      method: "POST",
      data: {
        deal_access: "Private",
        members: obj.members,
      },
    });
  },

  deactivateMember(memberId) {
    return axios({
      url: `${organisationUrl}/members/${memberId}/deactivate`,
      method: "PATCH",
    });
  },

  activateMember(memberId) {
    return axios({
      url: `${organisationUrl}/members/${memberId}/activate`,
      method: "PATCH",
    });
  },

  removeMember(memberId) {
    return axios({
      url: `/deal-member/${memberId}/remove-member`,
      method: "DELETE",
    });
  },

  fetchDealTransactions(query) {
    return axios({
      method: "GET",
      url: `/transaction/token/${query.id}`,
      params: {
        page: query.page,
        limit: query.limit,
        status: query.status,
      },
    });
  },

  transferToken(obj) {
    return axios({
      url: `/token/transfer`,
      method: "POST",
      data: obj,
    });
  },

  getDealRoomOrderBooks(query) {
    return axios({
      url: `/orders/token/${query.id}`,
      method: "GET",
      params: {
        type: query.type || null,
        page: query.page || null,
        limit: query.limit || null,
        status: query.status || null,
      },
    });
  },
  addDealUpdate({ id, payload }) {
    return axios({
      url: `/token/${id}/updates`,
      method: "POST",
      data: payload,
    });
  },
};
