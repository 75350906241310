import { getItem } from "@/utils/storage.js";
import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index";
const OnboardingView = () => import("@/views/OnboardingView.vue");
const DashboardView = () => import("@/views/DashboardView.vue");
const Four0FourView = () => import("@/views/404.vue");

const checkLoginState = (to, from, next) => {
	to.meta.title
		? (document.title = to.meta.title)
		: (document.title = "Dealroom");
	const details = getItem("org_details");
	if (!details) {
		return next({ name: "OnboardingView" });
	} else if (!details.verified) {
		return next({ name: "CodeVerification" });
	} else if (!details.pin_set) {
		return next({ name: "CreatePin" });
	} else if (
		to.name === "OverviewDetails" &&
		from.name === "SignIn" &&
		Object.values(details.kyc).includes("")
	) {
		return next({ name: "Setup" });
	} else if (details.verified) {
		return next();
	}
};

const checkToken = (to, from, next) => {
	to.meta.title
		? (document.title = to.meta.title)
		: (document.title = "Dealroom");
	const details = getItem("org_details");
	if (!details) return next();
	if (!details.verified) return next();
	if (details && details.founders && details.founders.length === 0)
		return next();
	if (!details.kyc.identityDocument) return next();
	if (!details.kyc.legalDocument) return next();
	if (!details.pin_set) return next();
	if (details.verified) return next("/dashboard");
	next();
};

const checkKycLoginState = async (to, from, next) => {
	to.meta.title
		? (document.title = to.meta.title)
		: (document.title = "Dealroom");
	await store.dispatch("organisationModule/getOrganisationProfile");
	const details = getItem("org_details");
	if (!details) return next({ name: "SignIn" });
	if (details.kyc.identityDocument !== "Approved") {
		if (details.kyc.identityDocument === "Pending") {
			store.dispatch(
				"showToast",
				{
					description: "Please hold on while we verify your Documents",
					display: true,
					type: "error",
				},
				{ root: true }
			);
		} else {
			store.dispatch(
				"showToast",
				{
					description: "Please complete KYC before creating Asset",
					display: true,
					type: "error",
				},
				{ root: true }
			);
		}
		return next({
			name: "Kyc",
		});
	}
	if (details.kyc.legalDocument !== "Approved") {
		if (details.kyc.legalDocument === "Pending") {
			store.dispatch(
				"showToast",
				{
					description: "Please hold on while we verify your Documents",
					display: true,
					type: "error",
				},
				{ root: true }
			);
		} else {
			store.dispatch(
				"showToast",
				{
					description: "Please complete KYC before creating Asset",
					display: true,
					type: "error",
				},
				{ root: true }
			);
		}
		return next({
			name: "Kyc",
		});
	}
	next();
};
const updatePageTitle = (to, from, next) => {
	to.meta.title
		? (document.title = to.meta.title)
		: (document.title = "Dealroom");
	next();
};

const routes = [
	{
		path: "/logout",
		name: "Logout",
		// beforeEnter: checkToken,
		component: () =>
			import(
				/* webpackChunkName: "logout" */
				"@/components/general/Logout.vue"
			),
	},
	{
		path: "/",
		name: "LandingView",
		component: () =>
			import(
				/* webpackChunkName: "onboarding" */
				"@/layout/landing/Homepage.vue"
			),
	},
	{
		path: "/onboarding",
		name: "OnboardingView",
		component: OnboardingView,
		redirect: { name: "SignIn" },
		children: [
			{
				path: "/onboarding/signin",
				name: "SignIn",
				beforeEnter: checkToken,
				meta: { title: "Sign in" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/SignIn.vue"
					),
			},
			{
				path: "/onboarding/data-collection",
				name: "DataCollection",
				beforeEnter: checkToken,
				meta: { title: "Data Collection" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/createAccount/DataCollection.vue"
					),
			},
			{
				path: "/onboarding/code-verification",
				name: "CodeVerification",
				beforeEnter: checkToken,
				meta: { title: "Verification" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/createAccount/CodeVerification.vue"
					),
			},
			{
				path: "/onboarding/welcome",
				name: "Welcome",
				beforeEnter: checkToken,
				meta: { title: "Welcome to GetEquity" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/createAccount/Welcome.vue"
					),
			},
			{
				path: "/onboarding/create-pin",
				name: "CreatePin",
				beforeEnter: updatePageTitle,
				meta: { title: "Create Pin" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/createAccount/CreatePin.vue"
					),
			},
			{
				path: "/onboarding/confirm-pin",
				name: "ConfirmPin",
				beforeEnter: updatePageTitle,
				meta: { title: "Confirm Pin" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/createAccount/ConfirmPin.vue"
					),
			},
			// {
			//     path: "/onboarding/contact-person",
			//     name: "ContactPerson",
			//     beforeEnter: checkToken,
			//     meta: { title: "Contact Person" },
			//     component: () =>
			//         import (
			//             /* webpackChunkName: "onboarding" */
			//             "@/layout/onboarding/kyc/ContactPerson.vue"
			//         ),
			// },
			{
				path: "/onboarding/request-link",
				name: "RequestLink",
				beforeEnter: checkToken,
				meta: { title: "Request Link" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/resetPassword/RequestLink.vue"
					),
			},
			{
				path: "/onboarding/reset-password",
				name: "ResetPassword",
				beforeEnter: checkToken,
				meta: { title: "Request Password" },
				component: () =>
					import(
						/* webpackChunkName: "onboarding" */
						"@/layout/onboarding/resetPassword/ResetPassword.vue"
					),
			},
			// {
			//     path: "/onboarding/upload-id",
			//     name: "UploadId",
			//     beforeEnter: checkToken,
			//     meta: { title: "ID Upload" },
			//     component: () =>
			//         import (
			//             /* webpackChunkName: "onboarding" */
			//             "@/layout/onboarding/kyc/UploadId.vue"
			//         ),
			// },
			// {
			//     path: "/onboarding/upload-document",
			//     name: "UploadDocument",
			//     beforeEnter: checkToken,
			//     meta: { title: "Upload Document" },
			//     component: () =>
			//         import (
			//             /* webpackChunkName: "onboarding" */
			//             "@/layout/onboarding/kyc/UploadDocument.vue"
			//         ),
			// },
			// {
			//     path: "/onboarding/selfie",
			//     name: "UploadSelfie",
			//     beforeEnter: checkToken,
			//     meta: { title: "Selfie Upload" },
			//     component: () =>
			//         import (
			//             /* webpackChunkName: "onboarding" */
			//             "@/layout/onboarding/kyc/UploadSelfie.vue"
			//         ),
			// },
		],
	},
	{
		path: "/setup",
		name: "Setup",
		meta: { title: "Complete your setup" },
		beforeEnter: checkLoginState,
		component: () =>
			import(
				/* webpackChunkName: "onboarding" */
				"@/layout/setup/Index.vue"
			),
	},
	{
		path: "/dashboard",
		component: DashboardView,
		redirect: { name: "OverviewDetails" },
		children: [
			{
				path: "",
				name: "DashboardView",
				redirect: { name: "OverviewDetails" },
			},
			{
				path: "/dashboard/overview",
				name: "OverviewDetails",
				beforeEnter: checkLoginState,
				meta: { title: "Overview" },
				component: () =>
					import(
						/* webpackChunkName: "overviewDetails" */
						"@/layout/overview/OverviewDetails.vue"
					),
			},
			{
				path: "/dashboard/deals",
				name: "Deals",
				beforeEnter: checkKycLoginState,
				meta: { title: "Deals" },
				component: () =>
					import(/* webpackChunkName: "deals" */ "@/layout/deals/Deals.vue"),
			},
			{
				path: "/dashboard/deals/asset/create",
				name: "CreateAsset",
				beforeEnter: checkKycLoginState,
				meta: { title: "Create Asset" },
				component: () =>
					import(
						/* webpackChunkName: "deals" */
						"@/layout/deals/createAsset/Index.vue"
					),
			},
			{
				path: "/dashboard/deals/new",
				name: "CreateDeal",
				beforeEnter: checkKycLoginState,
				meta: { title: "Create Deal" },
				component: () =>
					import(
						/* webpackChunkName: "deals" */
						"@/layout/deals/createDeal/Index.vue"
					),
			},
			{
				path: "/dashboard/deals/:dealId",
				name: "DealInfo",
				beforeEnter: checkLoginState,
				meta: { title: "Deal Info" },
				component: () =>
					import(/* webpackChunkName: "deals" */ "@/layout/deals/DealInfo.vue"),
			},
			{
				path: "/dashboard/spv",
				name: "Spv",
				beforeEnter: checkLoginState,
				meta: { title: "SPVs" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/Spv.vue"
					),
			},
			{
				path: "/dashboard/spv/:id",
				name: "SpvInfo",
				beforeEnter: checkLoginState,
				meta: { title: "SPV Details" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/SpvInfo.vue"
					),
			},
			{
				path: "/dashboard/spv/:id/link",
				name: "LinkSpv",
				beforeEnter: checkLoginState,
				meta: { title: "Link Deals to this SPV" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/SpvDeals.vue"
					),
			},
			{
				path: "/dashboard/spv/create",
				name: "CreateSpv",
				beforeEnter: checkLoginState,
				meta: { title: "Create SPV" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/CreateSpv.vue"
					),
			},
			{
				path: "/dashboard/spv/create/:id",
				name: "SpvBusinessInfo",
				beforeEnter: checkLoginState,
				meta: { title: "SPV Business Information" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/SpvFormSection.vue"
					),
			},
			{
				path: "/dashboard/spv/create/:id/compliance",
				name: "SpvCompliance",
				beforeEnter: checkLoginState,
				meta: { title: "SPV Compliance" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/Compliance.vue"
					),
			},
			{
				path: "/dashboard/spv/upload",
				name: "UploadSpv",
				beforeEnter: checkLoginState,
				meta: { title: "Upload SPV" },
				component: () =>
					import(
						/* webpackChunkName: "spv" */
						"@/layout/spv/UploadSpv.vue"
					),
			},
			{
				path: "/dashboard/invest",
				name: "Invest",
				beforeEnter: checkLoginState,
				meta: { title: "Invest" },
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */
						"@/layout/invest/Invest.vue"
					),
			},
			{
				path: "/dashboard/invest/orderbook",
				name: "InvestOrderbook",
				beforeEnter: checkLoginState,
				meta: { title: "Orderbook" },
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */
						"@/layout/invest/OrderBook.vue"
					),
			},
			{
				path: "/dashboard/invest/trade",
				name: "Trade",
				beforeEnter: checkLoginState,
				meta: { title: "Trade" },
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */
						"@/layout/trade/Trade.vue"
					),
			},
			{
				path: "/dashboard/invest/:symbol/:id",
				name: "TokenTrade",
				beforeEnter: checkLoginState,
				meta: { title: "Token Trade" },
				component: () =>
					import(
						/* webpackChunkName: "dashboard" */
						"@/layout/trade/TokenTrade.vue"
					),
			},
			{
				path: "/dashboard/wallet",
				name: "Wallet",
				beforeEnter: checkLoginState,
				meta: { title: "Wallet" },
				component: () =>
					import(/* webpackChunkName: "wallet" */ "@/layout/wallet/Wallet.vue"),
			},
			{
				path: "/dashboard/members",
				name: "Members",
				beforeEnter: checkLoginState,
				meta: { title: "Members" },
				component: () =>
					import(
						/* webpackChunkName: "members" */
						"@/layout/members/Members.vue"
					),
			},
			{
				path: "api-client",
				name: "ApiClient",
				beforeEnter: updatePageTitle,
				meta: { title: "API Client" },
				component: () =>
					import(
						/* webpackChunkName: "apiClient" */
						"@/layout/apiClient/Index.vue"
					),
			},
			{
				path: "api-client/customers",
				name: "ApiClientCustomers",
				beforeEnter: updatePageTitle,
				meta: { title: "API Client Customers" },
				component: () =>
					import(
						/* webpackChunkName: "apiClient" */
						"@/layout/apiClient/customers/AllCustomers.vue"
					),
			},
			{
				path: "api-client/customers/:email",
				name: "ApiClientCustomerTransaction",
				beforeEnter: updatePageTitle,
				meta: { title: "API Client Transaction" },
				component: () =>
					import(
						/* webpackChunkName: "apiClient" */
						"@/layout/apiClient/customers/CustomerTransactions.vue"
					),
			},
			{
				path: "api-client/transactions",
				name: "ApiClientTransactions",
				beforeEnter: updatePageTitle,
				meta: { title: "API Client Transaction" },
				component: () =>
					import(
						/* webpackChunkName: "apiClient" */
						"@/layout/apiClient/AllTransactions.vue"
					),
			},
			{
				path: "/dashboard/account",
				name: "Account",
				beforeEnter: checkLoginState,
				meta: { title: "Account" },
				redirect: { name: "ProfileInfo" },
				component: () =>
					import(
						/* webpackChunkName: "account" */
						"@/layout/account/Account.vue"
					),
				children: [
					{
						path: "profile-info",
						name: "ProfileInfo",
						beforeEnter: checkLoginState,
						meta: { title: "Profile Information" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/ProfileInfo.vue"
							),
					},
					{
						path: "kyc",
						name: "Kyc",
						beforeEnter: checkLoginState,
						meta: { title: "KYC Information" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/Kyc.vue"
							),
					},
					{
						path: "bank",
						name: "Bank",
						beforeEnter: checkLoginState,
						meta: { title: "KYC Information" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/BankInfo.vue"
							),
					},
					{
						path: "security",
						name: "Security",
						beforeEnter: checkLoginState,
						meta: { title: "Security" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/Security.vue"
							),
					},
					{
						path: "subscription-plans",
						name: "SubscriptionPlans",
						beforeEnter: checkLoginState,
						meta: { title: "Subscription Plans" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/SubscriptionPlan.vue"
							),
					},
					{
						path: "configurations",
						name: "Configuration",
						beforeEnter: checkLoginState,
						meta: { title: "Configuration" },
						component: () =>
							import(
								/* webpackChunkName: "account" */
								"@/layout/account/Configuration.vue"
							),
					},
				],
			},
			{
				path: "/new-password",
				name: "ChangePassword",
				beforeEnter: updatePageTitle,
				meta: { title: "Change Password" },
				component: () =>
					import(
						/* webpackChunkName: "ChangePassword" */
						"@/layout/account/ChangePassword.vue"
					),
			},
		],
	},
	{
		path: "/:pathMatch(.*)",
		name: "Four0FourView",
		component: Four0FourView,
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});

export default router;
