import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/styles/tailwind/tailwind.css";
import "../src/assets/styles/main.scss";
import vueCountryRegionSelect from "vue3-country-region-select";
import Loader from "@/components/notification/Loader.vue";
import SmallLoader from "@/components/notification/SmallLoader.vue";
import Tooltip from "@/components/general/Tooltip.vue";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import { Skeletor } from "vue-skeletor";
import "vue-skeletor/dist/vue-skeletor.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG,
    plugins: [new BugsnagPluginVue()],
});
const bugsnagVue = Bugsnag.getPlugin("vue");

const app = createApp(App);

app
    .use(store)
    .use(vueCountryRegionSelect)
    .use(router)
    .use(bugsnagVue)
    .component(Skeletor.name, Skeletor)
    .component("Loader", Loader)
    .component("Datepicker", Datepicker)
    .component("SmallLoader", SmallLoader)
    .component("Tooltip", Tooltip)
    .mount("#app");

app.config.unwrapInjectedRef = true;