export default {
  dealRequests: null,
  totalRequests: null,
  requestPage: null,
  requestPages: null,
  dealMembers: null,
  dealInvestors: null,
  members: null,
  dealInfo: null,
  DealTransactions: null,
  totalTransactions: null,
  page: 0,
  pages: 0,
};
