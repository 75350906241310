<template>
  <section
    :class="{ 'tw-relative': !isRelative }"
    @mouseover="currentVisible = text"
    @mouseleave="currentVisible = ''"
  >
    <span v-if="color === 'orange'">
      <img
        src="https://res.cloudinary.com/djalafcj9/image/upload/v1661781045/getequityV2/info-orange_o9aegn.svg"
        alt="info"
        class="tw-ml-1 tw-w-6 tw-h-6 tw-cursor-pointer"
        @click="setCurrentVisible"
      />
    </span>
    <span v-else>
      <img
        src="@/assets/img/info-grey.svg"
        alt="info"
        class="tw-ml-1 tw-w-4 tw-h-4 tw-cursor-pointer"
        @click="setCurrentVisible"
      />
    </span>
    <div
      class="tooltip__wrapper tw-p-4 tw-rounded tw-bg-secondary"
      :class="[
        isRelative ? 'tooltip__wrapper--relative' : 'tooltip__wrapper--left',
      ]"
      v-if="currentVisible === text"
    >
      <p class="tw-text-sm tw-text-white">{{ text }}</p>
    </div>
  </section>
</template>

<script>
  export default {
    name: "Tooltip",
    props: {
      text: {
        type: String,
        required: true,
      },
      color: {
        type: String,
        required: false,
      },
      isRelative: {
        type: Boolean,
        required: false,
      },
    },
    data() {
      return {
        showTooltip: false,
        currentVisible: "",
      };
    },

    methods: {
      setCurrentVisible() {
        this.currentVisible = this.text;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tooltip {
    &__wrapper {
      min-width: 300px;
      max-width: 300px;
      position: absolute;
      top: 20px;
      z-index: 10;
      &--left {
        left: -100px;
      }
      &--relative {
        left: -30px;
        top: 80px;
        @media (min-width: 768px) {
          left: 10px;
          top: 60px;
        }
      }
    }
  }
</style>
