import axios from "./axios";
const orgsApiUrl = `${process.env.VUE_APP_BASE_URL}/organisations`;
const baseUrl = `${process.env.VUE_APP_BASE_URL}`;
export default {
  getCustomers(params) {
    return axios({
      url: `${baseUrl}/api/customers`,
      params,
      method: "GET",
    });
  },

  getTotalTransactionVolume() {
    return axios({
      url: `${baseUrl}/api/transactions/volume`,
      method: "GET",
    });
  },

  toggleEnvironment(value) {
    return axios({
      url: `${orgsApiUrl}/toggle-environment`,
      method: "POST",
      data: value,
    });
  },
  getApikey() {
    return axios({
      url: `${orgsApiUrl}/api-keys`,
      method: "GET",
    });
  },
  generateApikey() {
    return axios({
      url: `${orgsApiUrl}/api-keys`,
      method: "POST",
      data: {
        live: process.env.VUE_APP_LIVE_STATUS
      },
    });
  },
  getApiWebHook() {
    return axios({
      url: `${orgsApiUrl}/webhooks`,
      method: "GET",
    });
  },
  addWebHook(payload) {
    return axios({
      url: `${orgsApiUrl}/webhooks`,
      method: "POST",
      data: payload,
    });
  },
  updateWebhook(payload) {
    return axios({
      url: `${orgsApiUrl}/webhooks`,
      method: "PATCH",
      data: payload,
    });
  },
  searchCustomers(name) {
    return axios({
      url: `${orgsApiUrl}/api/customers/search?name=${name}`,
      method: "GET",
    });
  },
  getCustomerTransactions(params) {
    return axios({
      url: `${orgsApiUrl}/api/customer-transactions`,
      params,
      method: "GET",
    });
  },
  getApiTransactions(params) {
    return axios({
      url: `${baseUrl}/transaction/organisation`,
      params,
      method: "GET",
    });
  },
};
