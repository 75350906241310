export const signinLoading = ({ commit }, payload) => {
  commit("SET_SIGNIN_LOADING", payload);
};

export const signinSuccess = ({ commit }, payload) => {
  commit("SET_SIGNIN_SUCCESS", payload);
};

export const createLoading = ({ commit }, payload) => {
  commit("SET_CREATE_LOADING", payload);
};

export const createSuccess = ({ commit }, payload) => {
  commit("SET_CREATE_SUCCESS", payload);
};

export const verifyLoading = ({ commit }, payload) => {
  commit("SET_VERIFY_LOADING", payload);
};

export const verifySuccess = ({ commit }, payload) => {
  commit("SET_VERIFY_SUCCESS", payload);
};

export const mailListLoading = ({ commit }, payload) => {
  commit("SET_MAIL_LIST_LOADING", payload);
};

export const mailListSuccess = ({ commit }, payload) => {
  commit("SET_MAIL_LIST_SUCCESS", payload);
};

export const contactPersonLoading = ({ commit }, payload) => {
  commit("SET_CONTACT_PERSON_LOADING", payload);
};

export const contactPersonSuccess = ({ commit }, payload) => {
  commit("SET_CONTACT_PERSON_SUCCESS", payload);
};

export const requestLinkLoading = ({ commit }, payload) => {
  commit("SET_LINK_LOADING", payload);
};

export const requestLinkSuccess = ({ commit }, payload) => {
  commit("SET_LINK_SUCCESS", payload);
};

export const resetPasswordLoading = ({ commit }, payload) => {
  commit("SET_PASSWORD_LOADING", payload);
};

export const resetPasswordSuccess = ({ commit }, payload) => {
  commit("SET_PASSWORD_SUCCESS", payload);
};
