import Vuex from "vuex";
import state from "./state";
import * as getters from "./getters";
import * as mutations from "./mutations";
import * as actions from "./actions";
import organisationModule from "./organisationModule/index";
import loadingModule from "./loadingModule/index";
import fileUploads from "./fileUploadsModule/index";
import Asset from "./assetModule/index";
import Deals from "./dealsModule/index";
import Spv from "./spvModule/index";
import Account from "./accountsModule/index";
import ApiClient from "./apiClientModule/index";

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    organisationModule,
    fileUploads,
    loadingModule,
    Deals,
    Asset,
    Spv,
    Account,
    ApiClient,
  },
});
