import api from "@/services/asset";
import deal from "@/services/deal";
import responseHandler from "@/utils/responseHandler.js";
import { getItem } from "@/utils/storage.js";
import router from "@/router";

export const createAsset = async ({ commit }, asset) => {
  try {
    const response = await api.createAsset(asset);
    const { data } = response.data;
    commit("SET_ASSET", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const updateAsset = async ({ commit }, { asset, assetId }) => {
  return new Promise((resolve, reject) => {
    api
      .updateAsset({ asset, assetId })
      .then((response) => {
        const { data } = response.data;
        commit("SET_ASSET", data);
        resolve(response);
      })
      .catch((error) => {
        responseHandler.handleError(error);
        reject(error);
      });
  });
};

export const deleteFounder = async ({}, { id, asset }) => {
  try {
    const response = await api.deleteFounder({ id, asset });
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const searchAsset = async ({ commit }, assetName) => {
  try {
    const response = await api.searchAsset(assetName);
    const { data } = response.data;
    commit("SET_ASSETS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const fetchAsset = async ({ commit }, assetId) => {
  try {
    const response = await api.fetchAsset(assetId);
    const { data } = response.data;
    commit("SET_ASSET", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const fetchAssetDraft = async ({ commit }) => {
  try {
    const orgId = getItem("org_details")._id;
    const response = await api.fetchAssetDraft(orgId);
    const { data } = response.data;
    commit("SET_ASSET_DRAFTS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const getTokens = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getTokens(payload);
    const { tokens, page, pages } = response.data.data;
    commit("SET_USER_TOKEN", tokens);
    commit("SET_TOKEN_PAGE", page);
    commit("SET_TOKEN_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    responseHandler.handleError(error);
    dispatch("setSmallLoading", false, { root: true });
    return false;
  }
};

export const getSingleToken = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getSingleToken(payload);
    const { data } = response.data;
    commit("SET_SINGLE_TOKEN", data);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const getTokenUpdate = async ({ commit }, payload) => {
  try {
    const response = await api.getTokenUpdate(payload);
    const { updates, page, pages } = response.data.data;
    commit("SET_TOKEN_UPDATE", updates);
    commit("SET_TOKEN_UPDATE_PAGE", page);
    commit("SET_TOKEN_UPDATE_PAGES", pages);
    return true;
  } catch (error) {
    responseHandler.handleError(error);
    return false;
  }
};

export const getTokenBalance = async ({ commit }, payload) => {
  try {
    const response = await api.getTokenBalance(payload);
    const { data } = response.data;
    commit("SET_TOKEN_BALANCE", data);
    return data;
  } catch (error) {
    responseHandler.handleError(error);
    return false;
  }
};

export const getCompaniesRaising = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getCompaniesRaising(payload);
    const { tokens, page, pages } = response.data.data;
    commit("SET_COMPANIES_RAISING", tokens);
    if (payload.page === 1 && router.currentRoute.value.name !== "Invest") {
      commit("SET_RECENT_RAISING", tokens);
    }
    commit("SET_RAISING_PAGE", page);
    commit("SET_RAISING_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const getCompaniesRaised = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getCompaniesRaised(payload);
    const { tokens, page, pages } = response.data.data;
    commit("SET_COMPANIES_RAISED", tokens);
    commit("SET_RAISED_PAGE", page);
    commit("SET_RAISED_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    console.log(error);
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const getOrderBook = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getOrderBooks(payload);
    const { orders, total, page, pages } = response.data.data;
    commit("SET_ORDER_BOOK", orders);
    commit("SET_TOTAL_ORDERS", total);
    commit("SET_ORDER_PAGE", page);
    commit("SET_ORDER_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const getDealRoomOrderBooks = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await deal.getDealRoomOrderBooks(payload);
    const { orders, total, page, pages } = response.data.data;
    commit("SET_ORDER_BOOK", orders);
    commit("SET_TOTAL_ORDERS", total);
    commit("SET_ORDER_PAGE", page);
    commit("SET_ORDER_PAGES", pages);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const getTokenOrders = async ({ commit, dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    const response = await api.getTokenOrders(payload);
    const { orders } = response.data.data;
    if (payload.data.type === "Buy") commit("SET_BUY_ORDER_BOOK", orders);
    if (payload.data.type === "Sell") commit("SET_SELL_ORDER_BOOK", orders);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};

export const cancelOrder = async ({ dispatch }, payload) => {
  dispatch("setSmallLoading", true, { root: true });
  try {
    await api.cancelOrder(payload);
    dispatch("setSmallLoading", false, { root: true });
    return true;
  } catch (error) {
    dispatch("setSmallLoading", false, { root: true });
    responseHandler.handleError(error);
    return false;
  }
};
