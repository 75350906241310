export const SET_SIGNIN_LOADING = (state, payload) => {
  state.signinLoading = payload;
};

export const SET_SIGNIN_SUCCESS = (state, payload) => {
  state.signinSuccess = payload;
};

export const SET_CREATE_LOADING = (state, payload) => {
  state.createLoading = payload;
};

export const SET_CREATE_SUCCESS = (state, payload) => {
  state.createSuccess = payload;
};

export const SET_VERIFY_LOADING = (state, payload) => {
  state.verifyLoading = payload;
};

export const SET_VERIFY_SUCCESS = (state, payload) => {
  state.verifySuccess = payload;
};

export const SET_MAIL_LIST_LOADING = (state, payload) => {
  state.mailListLoading = payload;
};

export const SET_MAIL_LIST_SUCCESS = (state, payload) => {
  state.mailListSuccess = payload;
};

export const SET_CONTACT_PERSON_LOADING = (state, payload) => {
  state.contactPersonLoading = payload;
};

export const SET_CONTACT_PERSON_SUCCESS = (state, payload) => {
  state.contactPersonSuccess = payload;
};

export const SET_LINK_LOADING = (state, payload) => {
  state.requestLinkLoading = payload;
};

export const SET_LINK_SUCCESS = (state, payload) => {
  state.requestLinkSuccess = payload;
};

export const SET_PASSWORD_LOADING = (state, payload) => {
  state.resetPasswordLoading = payload;
};

export const SET_PASSWORD_SUCCESS = (state, payload) => {
  state.resetPasswordSuccess = payload;
};
