export default {
  toast: {
    description: "",
    display: false,
    type: "",
  },
  loading: false,
  smallLoading: false,
  carousel: [],
};
