export default {
    loading: false,
    customers: null,
    customerSearchResult: null,
    totalTransactionVolume: null,
    apiKeyInfo: null,
    webhook: null,
    customerResult: null,
    customerTransactions: null,
    transactions: null,
};