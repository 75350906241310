export default {
  signinLoading: false,
  signinSuccess: false,
  createLoading: false,
  createSuccess: false,
  verifyLoading: false,
  verifySuccess: false,
  mailListLoading: false,
  mailListSuccess: false,
  contactPersonLoading: false,
  contactPersonSuccess: false,
  requestLinkLoading: false,
  requestLinkSuccess: false,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
};
