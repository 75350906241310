export default {
  spvRequests: {},
  spvInfo: null,
  spvs: null,
  spvCountries: [
    // "Nigeria",
    // "United States",
    // "Egypt",
    "Ghana",
    "Kenya",
    "South Africa",
    "Zimbabwe",
    "Zambia",
    "Senegal",
    "Cote d'Ivoire",
    // "Cameroon",
    // "Tanzania",
    "Uganda",
    "Rwanda",
  ],
  uploadSpvCountries: [
    "Nigeria",
    "United States",
    "Egypt",
    "Ghana",
    "Kenya",
    "South Africa",
    "Zimbabwe",
    "Zambia",
    "Senegal",
    "Cote d'Ivoire",
    "Cameroon",
    "Tanzania",
    "United Kingdom",
    "Uganda",
    "Rwanda",
  ],
};
