export const organisationDetails = (state) => state.organisationDetails;
export const products = (state) =>
	state.organisationDetails.products.split(" ");
export const transactionFees = (state) => state.transactionFees;

export const overviewDeals = (state) => state.overviewDeals;
export const overviewMembers = (state) => state.overviewMembers;
export const overviewTokens = (state) => state.overviewTokens;
export const walletBalance = (state) => {
	const balances = state.walletBalance;
	const dataArray =
		balances &&
		Object.entries(balances).map(([value, balance]) => ({
			value,
			currency: `${value.substring(1)}`,
			balance,
		}));
	return dataArray;
};
export const tradeBalance = (state) => state.tradeBalance;
export const exchangeRate = (state) => state.exchangeRate;
export const currency = (state) => state.currency;
export const entityCard = (state) => state.entityCard;
export const fundMethod = (state) => state.fundMethod;
export const virtualAccount = (state) => state.virtualAccount;
export const getequityProducts = (state) => state.getequityProducts;
export const plans = (state) => state.plans;
export const plan = (state) => state.plan;
export const members = (state) => state.members;
export const totalMembers = (state) => state.totalMembers;
export const minimumDeposit = (state) => state.minimumDeposit && Number(state.minimumDeposit[state.currency.toLowerCase()]);
export const currencyRate = (state) => state.currencyRate
