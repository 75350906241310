import api from "@/services/org";
import auth from "@/services/auth";
import responseHandler from "@/utils/responseHandler.js";
import { setItem } from "@/utils/storage.js";

export const fetchSubscriptions = async ({ commit }) => {
  try {
    const response = await api.fetchSubscriptions();
    const { data } = response.data;
    commit("SET_SUBSCRIPTIONS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const updateSubscription = async ({ commit }, subscription) => {
  try {
    const response = await api.updateSubscription(subscription);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const fetchBankAccounts = async ({ commit }) => {
  try {
    const response = await api.fetchBankAccounts();
    const { data } = response.data;
    commit("SET_BANK_ACCOUNTS", data);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
export const addBankAccount = ({}, payload) => {
  return new Promise((resolve, reject) => {
    api
      .addBankAccount(payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        responseHandler.handleError(error);
        reject(error);
      });
  });
};

export const deleteBank = async ({}, bank) => {
  try {
    const response = await api.deleteBank(bank);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
export const verifyPassword = async ({}, bank) => {
  return new Promise((resolve, reject) => {
    auth
      .loginOrganisation(bank)
      .then((response) => {
        const { data } = response.data;
        setItem("org_token", data.token);
        resolve(response);
      })
      .catch((error) => {
        responseHandler.handleError(error);
        reject(error);
      });
  });
};

export const resetPin = async ({}, bank) => {
  try {
    const response = await auth.resetPin(bank);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};

export const addMemberBankAccount = async ({}, payload) => {
  try {
    const response = await api.addMemberBankAccount(payload);
    return response;
  } catch (error) {
    responseHandler.handleError(error);
    return error;
  }
};
