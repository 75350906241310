export const spvRequests = (state) =>
  state.spvRequests && state.spvRequests.spvs;
export const spvRequestsCount = (state) =>
  state.spvRequests && state.spvRequests.total;
export const currentSpvRequestsPage = (state) =>
  state.spvRequests && state.spvRequests.page;
export const totalSpvRequestsPages = (state) =>
  state.spvRequests && state.spvRequests.pages;
export const spvInfo = (state) => state.spvRequests && state.spvInfo;
export const spvs = (state) => state.spvs && state.spvs.spvs;
export const spvCount = (state) => state.spvs && state.spvs.total;
export const CurrentSpvPage = (state) => state.spvs && state.spvs.page;
export const totalSpvPages = (state) => state.spvs && state.spvs.pages;
export const spvCountries = (state) => state.spvCountries;
export const uploadSpvCountries = (state) => state.uploadSpvCountries;
