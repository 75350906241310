import store from "../store/index";

export default {
  handleError(error) {
    if (error && error.response && error.response.status === 500) {
      return store.dispatch(
        "showToast",
        {
          description: "An error occurred, please contact support",
          display: true,
          type: "error",
        },
        { root: true }
      );
    }
    if (error.message === "Network Error") {
      store.dispatch(
        "showToast",
        {
          description:
            "Connection not established, please check your internet connection",
          display: true,
          type: "error",
        },
        { root: true }
      );
    } else if (error.message === "Ensure to select documents for all!") {
      store.dispatch(
        "showToast",
        {
          description: error.message,
          display: true,
          type: "error",
        },
        { root: true }
      );
    } else if (error.message === "Request failed with status code 401") {
      store.dispatch("logout");
      store.dispatch("showToast", {
        description: error.response.data.message,
        display: true,
        type: "error",
      });
    } else if (error.message === "timeout of 0ms exceeded") {
      store.dispatch("showToast", {
        description: error.message,
        display: true,
        type: "error",
      });
    }
    //  else if (error.message) {
    //   store.dispatch(
    //     "showToast",
    //     {
    //       description: error.message,
    //       display: true,
    //       type: "error",
    //     },
    //     { root: true }
    //   );
    // }
    else if (error.response.data.error && error.response.data.error.message) {
      store.dispatch(
        "showToast",
        {
          description: error.response.data.error.message,
          display: true,
          type: "error",
        },
        { root: true }
      );
    } else {
      store.dispatch(
        "showToast",
        {
          description: error.response.data.message,
          display: true,
          type: "error",
        },
        { root: true }
      );
    }
  },
  handleSuccess(message) {
    store.dispatch(
      "showToast",
      {
        description: message,
        display: true,
        type: "success",
      },
      { root: true }
    );
  },
};
